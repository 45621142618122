import { useMemo } from 'react'
import { formatDateRangeReadable } from 'data/helpers/dates'
import { CalendarSearchBarIcon } from 'components/icons'
import Button from 'components/home/common/Button'
import NavbarButton from '../../common/NavbarButton'
import ControlledDateRangePicker from 'components/form/Home/ControlledDateRangePicker'
import { useFormContext } from 'react-hook-form'
import { DestinationSearchFormFields } from 'components/home/navbar/destination/DestinationNavbar'
import ControlledNavbarPopover from '../components/ControlledNavbarPopover'
import { PropertyNavbarFormFields } from 'components/property/PropertyNavbar'
import { Modifier } from 'react-day-picker'
import classNames from 'classnames'

interface DateRangeSelectionPopoverProps {
  disabledDates?: Modifier[]
  className?: {
    container?: string
    button?: string
    panel?: string
    overlay?: string
  }
}

const DateRangeSelectionPopover = ({
  disabledDates,
  className,
}: DateRangeSelectionPopoverProps) => {
  const { setValue, watch } = useFormContext<DestinationSearchFormFields | PropertyNavbarFormFields>()

  const formattedDatesText = useMemo(() => {
    const dates = watch('dates')

    return formatDateRangeReadable(dates?.startDate, dates?.endDate)
  }, [watch('dates')])

  return (
    <ControlledNavbarPopover
      className={{
        panel: classNames('p-40', className?.panel),
        button: classNames('!mx-0', className?.button),
      }}
      trigger={({ isOpen, setIsOpen }) => (
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center h-full whitespace-nowrap"
        >
          <NavbarButton
            prefix={<CalendarSearchBarIcon />}
            active={!!formattedDatesText}
            className={{ container: classNames(
              'min-w-[200px] !justify-start',
              {
                'px-20': formattedDatesText,
                'pl-50 pr-20': !formattedDatesText,
              },
            ) }}
          >
            {formattedDatesText ?? 'Dates'}
          </NavbarButton>
        </button>
      )}
    >
      {({ setIsOpen }) => (
        <>
          <ControlledDateRangePicker
            name="dates"
            disabledDays={[
              { before: new Date() },
              ...(disabledDates ?? []),
            ]}
          />

          <div className="flex justify-between gap-20 mt-4">
            <Button
              variant="secondary"
              style="pill"
              onClick={() => setValue('dates', {
                startDate: undefined,
                endDate: undefined,
              })}
            >
              Clear Dates
            </Button>

            <Button
              style="pill"
              onClick={() => setIsOpen(false)}
            >
              Confirm Dates
            </Button>
          </div>
        </>
      )}
    </ControlledNavbarPopover>

  )
}

export default DateRangeSelectionPopover
