import PublicModal from 'components/PublicModal'
import Button from 'components/Button'
import { Share } from 'data/enums/share-target'
import { CopySimpleIcon, FacebookIcon, MailOutlineIcon, WhatsappIcon } from 'components/icons'
import ShareButton from 'components/magazines/ShareButton'
import Image from 'components/Image'
import placeholderImage from 'assets/images/placeholder.png'
import { useCopyToClipboard } from 'data/hooks/useCopyToClipboard'
import LogoLink from 'components/logoLink'
import { getPropertyMainImageUrl } from 'data/helpers/properties'
import { PropertyPageQuery } from 'gql/graphql'

type PropTypes = {
  isOpen: boolean,
  onClose: () => void
  property: PropertyPageQuery['property']
}

const SharePropertyModal = ({
  isOpen,
  onClose,
  property,
}: PropTypes) => {
  const { copyToClipboard } = useCopyToClipboard()

  if (!property) { return null }

  return (
    <PublicModal
      isOpen={isOpen}
      onClose={onClose}
      className="!pt-40 !px-20 flex flex-col gap-30 relative max-w-[375px]"
    >
      <div className="flex justify-center">
        <LogoLink className="w-[130px] focus:outline-none" />
      </div>
      <div>
        <h3 className="font-bold text-center text-16 leading-24">Share this property</h3>
      </div>
      <div className="flex flex-col justify-center">
        <div className="relative w-full h-full min-h-[200px]">
          <Image
            src={getPropertyMainImageUrl(property) ?? placeholderImage}
            alt={property.main_image_alt_text ?? property.title}
            objectFit="cover"
            layout="fill"
            className="rounded-4"
          />
        </div>
        <span className="mt-4 font-serif text-22">{property.title}</span>
        <span className="text-gray-800 text-12">{property.guests} Guests - {property.bedrooms} Bedrooms - {property.bathrooms} Baths</span>
      </div>
      <div className="flex flex-col gap-12 font-normal text-14 text-grey-900 [&>*]:max-w-full">
        <ShareButton
          shareTarget={Share.FACEBOOK}
          shareUrl={property.url}
          icon={<FacebookIcon className="text-black text-22" />}
        />
        <Button
          onClick={() => {
            copyToClipboard(window.location.href)
            onClose()
          }}
          variant="wishlist"
          padding="px-30"
        >
          <p className="text-14">Copy Link</p>
          <CopySimpleIcon className="text-22"/>
        </Button>
        <ShareButton
          shareTarget={Share.WHATSAPP}
          shareUrl={property.url}
          shareText="Check out this property on A.M.A Selections!"
          icon={<WhatsappIcon className="text-black text-22" />}
        />
        <ShareButton
          shareTarget={Share.EMAIL}
          shareUrl={property.url}
          shareText="I'd like to share a link with you"
          icon={<MailOutlineIcon className="text-black text-22" />}
        />
      </div>
    </PublicModal>
  )
}

export default SharePropertyModal
