import { useState } from 'react'
import { ShareIcon } from 'components/icons'
import SharePropertyModal from 'components/modals/SharePropertyModal'
import classNames from 'classnames'
import { PropertyPageQuery, WishlistQuery } from 'gql/graphql'
import IconButton from 'components/IconButton'
import ShareWishlistModal from '../ShareWishlistModal'

export interface ShareModalTriggerProps {
  model: PropertyPageQuery['property'] | WishlistQuery['wishlist']
  iconSize?: 18 | 20 | 22 | 24
}

const ShareModalTrigger = ({ model, iconSize = 18 }: ShareModalTriggerProps) => {

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <IconButton
        variant="navbar"
        className={classNames({
          'text-18': iconSize === 18,
          'text-20': iconSize === 20,
          'text-22': iconSize === 22,
          'text-24': iconSize === 24,
        })}
        onClick={() => setIsOpen(true)}
      >
        <ShareIcon className="stroke-2" />
      </IconButton>

      {
        model?.__typename === 'Property' && (
          <SharePropertyModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            property={model}
          />
        )
      }

      {
        model?.__typename === 'Wishlist' && (
          <ShareWishlistModal
            wishlist={model}
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
          />
        )
      }
    </>
  )
}

export default ShareModalTrigger
