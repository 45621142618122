import { useEffect, useMemo } from 'react'
import { CopyThinIcon, FacebookIcon, MailOutlineIcon, WhatsappIcon } from 'components/icons'
import { Share } from 'data/enums/share-target'
import { RandomRegionQuery, Wishlist } from 'gql/graphql'
import ShareModal from './home/ShareModal'
import { useRouter } from 'next/router'

type ShareWishlist = Pick<Wishlist, 'id' | 'title'> &
{
  users: Pick<Wishlist['users'][number], 'id' | 'first_name' | 'last_name' | 'email'>[]
  userInvites: Pick<Wishlist['userInvites'][number], 'id' | 'name' | 'email'>[]
  owner: Pick<Wishlist['owner'], 'id' | 'first_name' | 'last_name' | 'email'>
}

export interface ShareWishlistModalProps {
  wishlist: ShareWishlist
  randomRegion?: RandomRegionQuery
  isOpen?: boolean
  onClose?: () => void
  refetch?: () => void
}

const ShareWishlistModal = ({
  wishlist,
  randomRegion,
  isOpen = false,
  onClose = () => { },
  refetch = () => { },
}: ShareWishlistModalProps) => {
  const router = useRouter()
  const shareLink = useMemo(
    () => router.asPath,
    [router.asPath],
  )

  useEffect(() => {
    if (isOpen) {
      refetch()
    }
  }, [isOpen])

  return (
    <>
      <ShareModal
        isOpen={isOpen}
        onClose={onClose}
        refetch={refetch}
        title="Share this Wishlist"
        model={wishlist}
        randomRegion={randomRegion}
        description="Invited guests will be able to view & add their favorite properties directly from their account."
        links={[
          {
            target: Share.FACEBOOK,
            icon: <FacebookIcon className="text-22" />,
            href: shareLink,
          },
          {
            target: Share.CLIPBOARD,
            icon: <CopyThinIcon className="text-22" />,
            href: shareLink,
          },
          {
            target: Share.WHATSAPP,
            icon: <WhatsappIcon className="text-22" />,
            href: shareLink,
          },
          {
            target: Share.EMAIL,
            icon: <MailOutlineIcon className="text-22" />,
            href: shareLink,
          },
        ]}
      />
    </>
  )
}

export default ShareWishlistModal
