import React from 'react'
import PropTypes from 'prop-types'
import { getShareLink } from 'data/helpers/share'

const ShareButton = ({ shareTarget, shareUrl, shareText = '', icon }) => {
  return (
    <a className="sm:max-w-[200px] w-full" href={getShareLink(shareTarget, shareUrl, shareText)} target="_blank" rel="noreferrer">
      <button className="flex items-center justify-between w-full h-60 bg-grey-100 hover:bg-grey-250 rounded-4 px-30 text-grey-900">
        <p className="text-14 leading-20">{shareTarget}</p>
        <div>{icon}</div>
      </button>
    </a>
  )
}

ShareButton.propTypes = {
  shareTarget: PropTypes.string,
  shareUrl: PropTypes.string,
  shareText: PropTypes.string,
  icon: PropTypes.node,
}

export default ShareButton
